import {api} from '@moveup/app-core';

require('json.date-extensions');

const axiosJSONDateParseParams = {
    transformResponse: data => JSON.parseWithDate(data),
};

const initialState = {};

const store = {
    state: initialState,
    mutations: {},
    getters: {},
    actions: {
        getReport(_, params) {
            let lanaguageParameter;
            if(params?.language){
                lanaguageParameter = {language: params.language}
            }
            return api.get(`report/${params.patientId}/${params.coupleId}`, lanaguageParameter);
        },

        getReportPDF(_, params) {
            let lanaguageParameter;
            if(params?.language){
                lanaguageParameter = {language: params.language}
            }
            return api.get(`report/${params.patientId}/${params.coupleId}/pdf`, lanaguageParameter, {
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'application/pdf',
                },
            });
        },
    },
};

export default store;
